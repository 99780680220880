// Images
require.context('../images', true)


// Config Bootstrap + Fontawesome
import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";


// AOS
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
  duration: 1200,
});


// Anime.js
import anime from 'animejs/lib/anime.es.js';


// Feature Bootstrap en JS
document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
})


// Feature Back to top
$(document).ready(function(){
	$(window).scroll(function () {
			if ($(this).scrollTop() > 50) {
				$('#back-to-top').fadeIn();
			} else {
				$('#back-to-top').fadeOut();
			}
		});
		// scroll body to 0px on click
		$('#back-to-top').click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 400);
			return false;
		});
});
